<!--
 * @Description: 编辑测点
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-16 10:24:47
 * @LastEditors: 支磊
 * @LastEditTime: 2021-08-03 15:52:50
-->
<template>
  <div class="EditGroupComponents FontNoSelect">
    <div class="model"></div>
    <div class="main">
      <i class="close el-icon el-icon-close" @click="closeF"></i>
      <div class="head">编辑</div>
      <div class="content">
        <!-- left -->
        <div class="left">
          <div>
            <div class="lable">测点名称<span class="must">*</span></div>
            <el-input
              v-model="pointName"
              placeholder="请输入内容"
              size="small"
            ></el-input>
          </div>

          <div>
            <div class="lable">测点类型<span class="must">*</span></div>
            <el-select
              v-model="pointCategory"
              placeholder="请选择"
              size="small"
              style="width: 100%"
            >
              <el-option
                v-for="item in pointCategoryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div>
            <div class="lable">传感器大类</div>
            <el-select
              clearable
              v-model="sensorTypeIndex"
              @change="changeSensorTypeF"
              placeholder="请选择"
              size="small"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in sensorTypeOption"
                :key="item.categoryId"
                :label="item.categoryName"
                :value="index"
              >
              </el-option>
            </el-select>
          </div>

          <div>
            <div class="lable">传感器子类</div>
            <el-select
              clearable
              v-model="sensorTypeV"
              placeholder="请选择"
              size="small"
              style="width: 100%"
            >
              <el-option
                v-for="item in sensorTypeVO"
                :key="item.typeId"
                :label="item.typeName"
                :value="item.typeId"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <div class="lable">传感器</div>
            <el-select
              clearable
              v-model="serial"
              placeholder="请选择"
              size="small"
              style="width: 100%"
            >
              <el-option
                v-for="item in serialOptions"
                :key="item.sensorId"
                :label="item.serialNo"
                :value="item.sensorId"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <div class="lable">
              报警规则
              <el-tooltip
                class="item"
                effect="dark"
                content="添加规则"
                placement="right-start"
              >
                <span
                  class="el-icon-circle-plus-outline plus"
                  @click="addPlusF"
                ></span>
              </el-tooltip>
            </div>
            <div class="tagBox">
              <el-tag
                v-for="(tag, index) in selectRule"
                :key="tag.name"
                closable
                style="margin: 10px"
                :disable-transitions="false"
                @close="handleClose(index)"
              >
                {{ tag.name }}
              </el-tag>
            </div>
          </div>
        </div>
        <!-- left end -->
        <!-- right -->
        <div class="right">
          <div class="title">标注测点位置</div>
          <div class="rightContent">
            <div class="imgBox" ref="imgBox" @mousemove="mousemoveF">
              <img :src="imgUrl" alt="" />
              <div
                class="pointed"
                v-for="(item, index) in pointList"
                :key="index"
                :style="item.style"
              ></div>
              <div v-drag id="drag" v-if="isShowDrag" ref="drag"></div>
            </div>
            <div class="btn">
              <p class="add" @click="addPointF" v-if="!isShowDrag">添加测点+</p>
              <p class="delete" @click="isShowDrag = false" v-if="isShowDrag">
                删除测点-
              </p>
            </div>
          </div>
        </div>
        <!-- right end -->
      </div>
      <div class="foot">
        <el-button @click="closeF" size="small" class="cancelBtn" plain
          >取消</el-button
        >
        <el-button @click="submintF()" type="primary" size="small"
          >保存</el-button
        >
      </div>
    </div>
    <!-- 选择报警规则 -->
    <select-alert-rules
      v-if="isShowPlusTableS"
      @close="rulesCloseF"
      @pushData="getrulesDataF"
      :rejectIds="rejectIds"
    ></select-alert-rules>
    <!-- 选择报警规则 end -->
  </div>
</template>

<script>
import { sensorTypeList, availableList, update, detail } from "@/api/point.js";
import selectAlertRules from "./SelectAlertRulesDialogComponents";
export default {
  name: "EditGroupComponents",
  components: { selectAlertRules },
  props: {
    tableData: {
      default: () => {
        return [];
      },
    },
    editData: {},
    imgUrl: {},
  },
  fileter: {},
  directives: {
    drag: {
      // 指令的定义
      bind: (el, binding, vnode) => {
        let that = vnode.context;
        let odiv = el; //获取当前元素
        // 228px, 149px
        odiv.onmousedown = (e) => {
          //  算出鼠标相对元素的位置
          let disY = e.clientY - odiv.offsetTop;
          let disX = e.clientX - odiv.offsetLeft;
          document.onmousemove = (e) => {
            //  用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let top = e.clientY - disY;
            let height = 0;
            let width = 0;
            //  页面范围内移动元素
            if (top > -149 && top < 129) {
              odiv.style.top = top + "px";
            }
            let left = e.clientX - disX;
            if (left > -237 && left < 218) {
              odiv.style.left = left + "px";
            }
          };
          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
            // onmouseup 时的时间，并计算差值
          };
        };
      },
    },
  },
  data() {
    return {
      Tstyle: {
        x: 228,
        y: 149,
      },
      isShowDrag: false,
      pointCategory: "", //测点类型
      pointCategoryOptions: [
        { label: "固定", value: "1" },
        { label: "点检", value: "2" },
        { label: "巡检", value: "3" },
      ], //测点类型
      sensorType: "", //设备大类
      sensorTypeIndex: "",
      sensorTypeOption: [], //设备大类
      sensorTypeV: "", //
      sensorTypeVO: [],
      imgWidth: 0,
      imgHeight: 0,
      pointList: [],
      pointName: "",
      serialOptions: [], //传感器
      serial: "", //传感器 id
      isShowPlusTableS: false,
      selectRule: [],
      moveS: true,
      isDrag: false,
      Info: {},
    };
  },

  computed: {
    rejectIds() {
      let data = "";

      if (this.selectRule.length != 0) {
        for (let i = 0; i < this.selectRule.length; i++) {
          if (i != this.selectRule.length - 1) {
            data = data + this.selectRule[i].id + ",";
          } else {
            data = data + this.selectRule[i].id;
          }
        }
      }
      return data;
    },
  },
  //监听执行
  watch: {
    sensorTypeV() {
      this.changeSensorTypeVF(this.sensorTypeV);
    },
  },
  mounted() {
    this.init();
    this.getSensorTypeListF();
    window.addEventListener("onmouseup", this.onmouseupF, false);
    this.getDetailF();
  },
  methods: {
    getDetailF() {
      detail(this.editData.instId).then((res) => {
        if (this.apiReply(res)) {
          let data = { ...res.data.data };
          this.Info = {
            ...data,
          };
          this.pointName = data.pointName;
          this.pointCategory = data.pointCategory + "";
          this.sensorType =
            data.sensorCategory == -1 ? "" : data.sensorCategory; //传感器大类
          this.sensorTypeV = data.sensorType == -1 ? "" : data.sensorType; //传感器子类
          this.serial = data.sensorId == -1 ? "" : data.sensorId; //传感器
          console.log("ruleVOList", data);
          if (data.ruleVOList) {
            this.selectRule = [...data.ruleVOList];
          } else {
            this.selectRule = [];
          }

          if (data.location != "") {
            this.isShowDrag = true;
          }
          this.pointList = this.pointListF(data.location);
        }
      });
    },
    submintF() {
      // 提交测点
      let left = "";
      let top = "";
      let odev = document.getElementById("drag");
      if (this.isShowDrag) {
        left = (
          (228 + parseInt(this.getStyle(odev, "left"))) /
          this.imgWidth
        ).toFixed(2);
        top = (
          (149 + parseInt(this.getStyle(odev, "top"))) /
          this.imgHeight
        ).toFixed(2);
      }

      let data = {
        instId: this.editData.instId,
        deviceId: this.$route.query.deviceId - 0,
        location: this.isShowDrag ? JSON.stringify({ x: left, y: top }) : "",
        pointCategory: this.pointCategory - 0,
        pointName: this.pointName,
        rules: this.rejectIds != "" ? this.rejectIds.split(",") : [],
        sensorCategory: this.sensorType ? this.sensorType : -1,
        sensorId: this.serial ? this.serial : -1,
        sensorType: this.sensorTypeV ? this.sensorTypeV : -1,
      };
      update(data).then((res) => {
        if (this.apiReply(res)) {
          this.$emit("update");
        }
      });
    },
    getStyle(obj, attr) {
      // obj 传递过来的对象；attr  attribute表示属性
      if (obj.currentStyle) {
        return obj.currentStyle[attr]; //IE 返回传递过来的某个属性
      } else {
        return window.getComputedStyle(obj, null)[attr]; //null表示伪元素
      }
    },
    mousemoveF() {
      if (this.moveS && this.isDrag) {
        this.moveS = false;
        setTimeout(() => {
          this.moveS = true;
        }, 200);
      }
    },
    onmouseupF() {
      this.isDrag = false;
      console.log("抬起");
    },
    mousedownF() {
      this.isDrag = true;
      console.log("按下", this.$refs.drag.style);
    },
    addPointF() {
      console.log("添加测点");
      this.isShowDrag = true;
    },
    handleClose(index) {
      this.selectRule.splice(index, 1);
    },
    getrulesDataF(data) {
      this.selectRule = this.selectRule.concat(data);
      console.log(">>>>>>>>", data);
    },
    rulesCloseF() {
      this.isShowPlusTableS = false;
    },
    addPlusF() {
      // 添加报警规则
      this.isShowPlusTableS = true;
    },
    changeSensorTypeVF(data) {
      // 选择设备子类

      if (data !== "") {
        let arr = [];
        let active = "";
        if (this.Info.sensorType == data) {
          arr = [
            {
              serialNo: this.Info.serialNo,
              sensorId: this.Info.sensorId,
            },
          ];
          active = this.Info.sensorId;
        }
        availableList(data).then((res) => {
          if (this.apiReply(res)) {
            this.serial = active;
            arr = arr.concat(res.data.data.dataList);
            this.serialOptions = [...arr];
          }
        });
      } else {
        this.serial = "";
        this.serialOptions = [];
      }
    },
    changeSensorTypeF(data) {
      // 选择设备大类
      if (data !== "") {
        this.sensorType = this.sensorTypeOption[data].categoryId;
        this.sensorTypeV = "";
        this.serial = "";
        this.sensorTypeVO = [...this.sensorTypeOption[data].sensorTypeVO];
      } else {
        console.log("删除设备大类", data);
        this.sensorType = "";
        this.sensorTypeV = "";
        this.serial = "";
        this.sensorTypeVO = [];
        this.serialOptions = [];
      }
    },
    init() {
      this.$nextTick(function () {
        this.imgWidth = this.$refs.imgBox.offsetWidth;
        this.imgHeight = this.$refs.imgBox.offsetHeight;
      });

      // console.log(JSON.parse(this.tableData[0].location).x * this.imgWidth);
    },
    getSensorTypeListF() {
      // 获取传感器类型列表
      sensorTypeList().then((res) => {
        if (this.apiReply(res)) {
          this.sensorTypeOption = [...res.data.data.sensorType];
          for (let i = 0; i < this.sensorTypeOption.length; i++) {
            console.log("editData", this.editData);
            if (
              this.sensorTypeOption[i].categoryId ==
              this.editData.sensorCategory
            ) {
              this.sensorTypeIndex = i;
              this.sensorTypeVO = [...this.sensorTypeOption[i].sensorTypeVO];
              break;
            }
          }
        }
        console.log(">>>>>>>>>>", res.data.data.sensorType);
      });
    },
    pointListF(location) {
      let tableData = [...this.tableData];
      let index = -1;
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i].location) {
          tableData[i].style =
            "transform:translate(" +
            (JSON.parse(tableData[i].location).x * this.imgWidth).toFixed(0) +
            "px," +
            (JSON.parse(tableData[i].location).y * this.imgHeight).toFixed(0) +
            "px)";
        }
        if (location != "" && location == tableData[i].location) {
          index = i;
        }
      }
      if (index != -1) {
        tableData.splice(index, 1);
        let left = (JSON.parse(location).x * this.imgWidth).toFixed(0) - 228;
        let top = (JSON.parse(location).y * this.imgHeight).toFixed(0) - 149;

        this.$nextTick(function () {
          console.log("refs", this.$refs.drag);
          this.$refs.drag.style.left = left + "px";
          this.$refs.drag.style.top = top + "px";
        });
      }
      return tableData;
    },
    closeF() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.FontNoSelect {
  @extend .FontNoSelect;
}
.must {
  color: red;
  margin-left: 5px;
}
//添加报警规则按钮
.plus {
  cursor: pointer;
  font-size: 16px;
  &:hover {
    color: $main_color;
  }
}
.EditGroupComponents {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  .model {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000;
  }
  .main {
    width: 970px;
    background: #fff;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    padding: 28px;
    .foot {
      padding-top: 20px;
      text-align: right;
    }
    .close {
      color: #909399;
      font-size: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 9;
      &:hover {
        color: $main_color;
      }
    }
    .head {
      @extend .title_green;
      margin-bottom: 6px;
    }
    .content {
      display: flex;
      > div {
        width: 50%;
        text-align: left;
      }
      // left
      .left {
        padding-right: 30px;
        .lable {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #232626;
          margin: 21px 0 12px;
        }
        .tagBox {
          min-height: 75px;
          max-height: 120px;
          border: 1px solid #dcdfe6;
          border-radius: 5px;
          overflow: auto;
        }
      }
      // left end
      // right
      .right {
        .title {
          margin-bottom: 12px;
          font-size: 14px;
        }
        .rightContent {
          border: 1px solid #efefef;
          height: 332px;
          .imgBox {
            height: 298px;
            position: relative;
            .pointed {
              background: #d8cfcf;
              border: 3px solid #fff;
              border-radius: 10px;
              width: 20px;
              height: 20px;
              cursor: move;
              position: absolute;
              top: 0;
              left: 0;
            }
            img {
              width: 100%;
              height: 100%;
              position: relative;
              z-index: -1;
            }
            #drag {
              position: absolute;
              z-index: 9;
              background: #adff2f;
              border: 3px solid #fff;
              border-radius: 10px;
              width: 20px;
              height: 20px;
              cursor: move;
              left: 0;
              top: 0;
              transform: translate(228px, 149px);
            }
          }
          .btn {
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #efefef;
            p {
              font-size: 14px;
              cursor: pointer;
              line-height: 32px;
            }
            p.add {
              &:hover {
                color: $main_color;
              }
            }
            p.delete {
              color: $delete_color;
            }
          }
        }
      }
      // right end
    }
  }
}
</style>
