<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-13 16:58:45
 * @LastEditors: 支磊
 * @LastEditTime: 2021-11-22 16:53:54
-->
<template>
  <div class="devienceGauging">
    <div class="Title">测点</div>
    <div class="head">
      <div class="select">
        <el-input
          prefix-icon="el-icon-search"
          size="small"
          placeholder="请输入内容"
          v-model="input"
          clearable
        >
        </el-input>
      </div>
      <div>
        <AddNewBtn @add="addF" />
      </div>
    </div>
    <!-- table -->
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="pointName" label="测点名称"> </el-table-column>
        <el-table-column align="center" label="测点类型">
          <template slot-scope="scope">
            {{ pointType[scope.row.pointCategory] }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="sensorCategoryName"
          label="传感器大类"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="sensorTypeName"
          label="传感器子类"
        >
        </el-table-column>
        <el-table-column align="center" prop="serialNo" label="传感器">
        </el-table-column>
        <el-table-column
          align="center"
          width="120px"
          prop="address"
          label="操作"
        >
          <template slot-scope="scope">
            <i class="el-icon-edit edit" @click="editF(scope.row)"></i>
            <i class="el-icon-delete delete" @click="deleteF(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- table end -->
    <div class="page">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <addDialog
      v-if="isShow"
      :tableData="tableData"
      :imgUrl="imgUrl"
      @updateImg="updateImgF"
      @close="isShow = false"
      @update="updateF"
    />
    <editDialog
      :editData="editData"
      v-if="isShowEdit"
      :tableData="tableData"
      :imgUrl="imgUrl"
      @updateImg="updateImgF"
      @close="isShowEdit = false"
      @update="updateeditF"
    />
  </div>
</template>

<script>
import { list, deletePoint } from "@/api/point.js";
import { detail } from "@/api/equipment.js";
import addDialog from "./private/AddGroupComponents";
import editDialog from "./private/EditGroupComponents";

export default {
  name: "devienceGauging",
  components: { addDialog, editDialog },
  data() {
    return {
      isShow: false, //添加测点弹窗显示状态
      isShowEdit: false,
      total: 0,
      pageSize: 20,
      input: "",
      tableData: [],
      currentPage: 1,
      pointType: {
        // 测点类型
        1: "固定",
        2: "点检",
        3: "巡检",
      },
      timeF: null,
      imgUrl: "",
      editData: {},
    };
  },

  computed: {},
  //监听执行
  watch: {
    input() {
      clearTimeout(this.timeF);
      this.timeF = setTimeout(() => {
        this.getData();
      }, 700);
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    updateImgF(data) {
      this.imgUrl = data;
    },
    editF(data) {
      this.editData = {
        ...data,
      };
      this.getData("isShowEdit");
    },
    updateF() {
      this.isShow = false;
      this.getData();
    },
    updateeditF() {
      this.isShowEdit = false;
      this.getData();
    },
    deleteF(data) {
      // 删除列
      this.$confirm("此操作将永久删除该测点, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deletePoint({ ids: [data.instId] }).then((res) => {
            if (this.apiReply(res)) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
    getData(type) {
      let data = {
        deviceId: this.$route.query.deviceId,
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        searchString: this.input,
      };
      list(data).then((res) => {
        if (this.apiReply(res)) {
          let resData = res.data.data;
          this.total = resData.total;
          this.tableData = [...resData.dataList];
          if (type) {
            this.getInfo(type);
          }
        }
      });
    },
    addF() {
      // 添加测点
      this.getData("isShow");
    },
    getInfo(type) {
      detail(this.$route.query.deviceId).then((res) => {
        if (this.apiReply(res)) {
          let deviceInfo = {
            ...res.data.data.deviceInfo,
          };
          let picList = deviceInfo.picList ? deviceInfo.picList : [];
          for (let i = 0; i < picList.length; i++) {
            if (picList[i].index == 1) {
              this.imgUrl = picList[i].picToken;
              break;
            }
          }
          if (type == "isShow") {
            this.isShow = true;
          }
          if (type == "isShowEdit") {
            this.isShowEdit = true;
          }
        }
      });
    },
    handleSizeChange(val) {
      this.getData();
    },
    handleCurrentChange(val) {
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.devienceGauging {
  padding: 20px;
  height: 100%;
  .Title {
    @extend .title_green;
  }
  .head {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    .select {
      width: 360px;
    }
  }
  .table {
    height: calc(100% - 130px);
  }
  .edit {
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      color: $main_color;
    }
  }
  .delete {
    cursor: pointer;
    color: $delete_color;
  }
}
</style>
