<!--
 * @Description: 频谱图
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-18 19:56:18
 * @LastEditors: 支磊
 * @LastEditTime: 2021-07-06 10:13:47
-->
<template>
  <div class="EchartWaveformComponents">
    <div class="Chart">
      <div class="title">速度均方根值趋势图</div>
      <div class="chart" ref="EchartWaveform1"></div>
    </div>
    <div class="Chart">
      <div class="title">波形图({{ nowTime }})</div>
      <div class="chart" ref="EchartWaveform2"></div>
    </div>
    <div class="Chart">
      <div class="title">频谱图({{ nowTime }})</div>
      <div class="chart" ref="EchartWaveform3"></div>
    </div>
  </div>
</template>

<script>
import { waveform } from "@/api/figure.js";
const echarts = require("echarts");
export default {
  name: "EchartWaveformComponents",
  components: {},
  props: {
    title: {},
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      timeData: null,
      waveformData: null,
      chart2: null,
      chart1: null,
      chart3: null,
      rawData: {},
    };
  },
  filters: {},
  computed: {
    nowTime() {
      let data = this.rawData;
      if (data.uploadTime) {
        return this.$formatDateTime(data.uploadTime, "yyyy-MM-dd HH:mm");
      } else {
        return "";
      }
    },
  },
  //监听执行
  watch: {
    chartData: {
      deep: true,
      handler: function (newV, oldV) {
        this.init();
      },
    },
  },
  mounted() {},
  methods: {
    init() {
      // 频谱图
      this.timeData = [];
      for (let i = 0; i < this.chartData.length; i++) {
        this.timeData.push(
          this.$formatDateTime(this.chartData[i].uploadTime, "MM-dd HH:mm")
        );
        if (this.chartData[i].rawDataFlag) {
          this.rawData = this.chartData[i];
        }
      }
      this.getWaveformDataF();
      this.chart1 ? this.chart1.dispose() : "";
      this.canvasF1();
    },
    getWaveformDataF() {
      // 频谱数据
      waveform({ id: this.rawData.id }).then((res) => {
        if (this.apiReply(res)) {
          this.waveformData = { ...res.data.data };
          this.chart2 ? this.chart2.dispose() : "";
          this.canvasF2();
          this.chart3 ? this.chart3.dispose() : "";
          this.canvasF3();
        }
      });
    },
    canvasF1() {
      let XArr = this.chartData.map(function (str) {
        return str.xeigenvalue.rms;
      });
      let YArr = this.chartData.map(function (str) {
        return str.yeigenvalue.rms;
      });
      let ZArr = this.chartData.map(function (str) {
        return str.zeigenvalue.rms;
      });
      let option = {
        title: {
          text: this.title.title1,
          show: false,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          selectedMode: "single",
          show: true,
          icon: "circle",
          left: "2%",
          data: ["X", "Y", "Z"],
        },
        grid: {
          left: "2%",
          right: "4%",
          top: "20%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          right: "4%",
          showTitle: false,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: {},
          },
          tooltip: {
            // 和 option.tooltip 的配置项相同
            show: true,
            formatter: function (param) {
              return "<div>" + param.title + "</div>"; // 自定义的 DOM 结构
            },
            backgroundColor: "#efefef",
            textStyle: {
              fontSize: 12,
            },
            extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);padding:6px", // 自定义的 CSS 样式
          },
        },
        xAxis: {
          data: this.timeData,
        },
        yAxis: [
          {
            name: "速度（nm/s²）",
          },
        ],
        series: [
          {
            name: "X",
            type: "line",
            color: "#80a188",
            areaStyle: {
              opacity: 0.1,
              color: "#80a188",
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: XArr,
          },
          {
            name: "Y",
            type: "line",
            color: "#1e87a9",
            areaStyle: { opacity: 0.1, color: "#1e87a9" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: YArr,
          },
          {
            name: "Z",
            type: "line",
            color: "#7c63ff",
            areaStyle: { opacity: 0.1, color: "#7c63ff" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: ZArr,
          },
        ],
      };
      this.chart1 = echarts.init(this.$refs.EchartWaveform1);
      this.chart1.setOption(option);
      this.chart1.on("legendselectchanged", (obj) => {
        this.chart2.dispatchAction({
          type: "legendToggleSelect",
          name: obj.name,
        });
        this.chart3.dispatchAction({
          type: "legendToggleSelect",
          name: obj.name,
        });
      });
    },
    canvasF2() {
      // xtimeDomain 波形图
      let i = 1;
      let number = this.waveformData.xtimeDomain.map((str) => {
        return i++;
      });

      let XArr = this.waveformData.xtimeDomain;
      let YArr = this.waveformData.ytimeDomain;
      let ZArr = this.waveformData.ztimeDomain;
      let option = {
        title: {
          text: this.title.title2,
          show: false,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          selectedMode: "single",
          show: false,
          icon: "circle",
          left: "2%",
          data: ["X", "Y", "Z"],
        },
        grid: {
          left: "2%",
          right: "4%",
          top: "20%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          right: "4%",
          showTitle: false,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: {},
          },
          tooltip: {
            // 和 option.tooltip 的配置项相同
            show: true,
            formatter: function (param) {
              return "<div>" + param.title + "</div>"; // 自定义的 DOM 结构
            },
            backgroundColor: "#efefef",
            textStyle: {
              fontSize: 12,
            },
            extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);padding:6px", // 自定义的 CSS 样式
          },
        },
        xAxis: {
          type: "category",
          name: "序号",
          data: number,
        },
        yAxis: [
          {
            name: "幅度（m/s²）",
          },
        ],
        series: [
          {
            name: "X",
            type: "line",
            color: "#80a188",
            areaStyle: {
              opacity: 0.1,
              color: "#80a188",
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: XArr,
          },
          {
            name: "Y",
            type: "line",
            color: "#1e87a9",
            areaStyle: { opacity: 0.1, color: "#1e87a9" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: YArr,
          },
          {
            name: "Z",
            type: "line",
            color: "#7c63ff",
            areaStyle: { opacity: 0.1, color: "#7c63ff" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: ZArr,
          },
        ],
      };
      this.chart2 = echarts.init(this.$refs.EchartWaveform2);
      this.chart2.setOption(option);
    },
    canvasF3() {
      // xtimeDomain 频谱

      let XData = this.waveformData.xfreqDomain;
      let YData = this.waveformData.yfreqDomain;
      let ZData = this.waveformData.zfreqDomain;
      let option = {
        title: {
          text: this.title.title3,
          show: false,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          selectedMode: "single",
          show: false,
          icon: "circle",
          left: "2%",
          data: ["X", "Y", "Z"],
        },
        grid: {
          left: "2%",
          right: "4%",
          top: "20%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          right: "4%",
          showTitle: false,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: {},
          },
          tooltip: {
            // 和 option.tooltip 的配置项相同
            show: true,
            formatter: function (param) {
              return "<div>" + param.title + "</div>"; // 自定义的 DOM 结构
            },
            backgroundColor: "#efefef",
            textStyle: {
              fontSize: 12,
            },
            extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);padding:6px", // 自定义的 CSS 样式
          },
        },
        xAxis: [
          {
            type: "category",
            name: "频率（Hz）",
            position: "bottom",
            data: XData.x,
          },
        ],
        yAxis: [
          {
            name: "幅度（m/s²）",
          },
        ],
        series: [
          {
            name: "X",
            type: "line",
            color: "#80a188",
            areaStyle: {
              opacity: 0.1,
              color: "#80a188",
            },

            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: XData.y,
          },
          {
            name: "Y",
            type: "line",
            color: "#1e87a9",

            areaStyle: { opacity: 0.1, color: "#1e87a9" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: YData.y,
          },
          {
            name: "Z",
            type: "line",
            color: "#7c63ff",

            areaStyle: { opacity: 0.1, color: "#7c63ff" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: ZData.y,
          },
        ],
      };
      this.chart3 = echarts.init(this.$refs.EchartWaveform3);
      this.chart3.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.EchartWaveformComponents {
  width: 100%;
  .Chart {
    width: 100%;
    .title {
      padding: 20px 0;
      text-align: left;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #313131;
    }
    .chart {
      width: 100%;
      height: 352px;
      > div {
        width: 100% !important;
        canvas {
          width: 100% !important;
        }
      }
    }
  }
}
</style>
