<!--
 * @Description: 棒值图
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-18 16:25:47
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-20 16:17:05
-->
<template>
  <div class="EchartBarComponents">
    <div class="title">最近更新：{{ time }}</div>
    <div class="main">
      <div class="echart" ref="barEchart1"></div>
      <div class="echart" ref="barEchart2"></div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "EchartBarComponents",
  props: {
    chartData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {};
  },

  computed: {
    time() {
      let time = "";
      if (this.chartData.uploadTime) {
        time = this.$formatDateTime(
          this.chartData.uploadTime,
          "yyyy-MM-dd HH:mm"
        );
      }
      return time;
    },
  },
  //监听执行
  watch: {
    chartData: {
      deep: true,
      handler: function (newV, oldV) {
        this.canvasF();
        this.canvasF2();
      },
    },
  },
  mounted() {},
  methods: {
    canvasF() {
      let bgColor = "#fff",
        fontColor = "#666";

      let xData = Object.keys(this.chartData.pk);
      let sData = xData.map((v) => this.chartData.pk[v]);

      let lineOption = {
        lineStyle: {
          color: "rgba(151,151,151,0.5)",
          type: "dashed",
        },
      };

      let option = {
        titleL: {
          text: "加速度峰值（m/s²）",
        },
        backgroundColor: bgColor,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "14%",
          right: "3%",
          left: "10%",
          bottom: "14%",
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisLine: lineOption,
            axisTick: {
              show: false,
            },
            axisLabel: {
              margin: 10,
              color: fontColor,
              textStyle: {
                fontSize: 14,
              },
            },
          },
        ],
        yAxis: [
          {
            position: "left",
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 14,
              color: "#333",
            },
            name: "加速度峰值（m/s²）",
            axisLabel: {
              formatter: "{value}",
              color: fontColor,
            },
            axisTick: {
              show: false,
            },
            axisLine: lineOption,
            splitLine: lineOption,
          },
        ],
        series: [
          {
            type: "bar",
            data: sData,
            barWidth: "20px",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#00BD89", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#C9F9E1", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
          },
        ],
      };

      let chart1 = echarts.init(this.$refs.barEchart1);
      chart1.setOption(option);
    },
    canvasF2() {
      let bgColor = "#fff",
        fontColor = "#666";

      let xData = Object.keys(this.chartData.rms);
      let sData = xData.map((v) => this.chartData.rms[v]);

      let lineOption = {
        lineStyle: {
          color: "rgba(151,151,151,0.5)",
          type: "dashed",
        },
      };

      let option = {
        titleL: {
          text: "加速度峰值（m/s²）",
        },
        backgroundColor: bgColor,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "14%",
          right: "3%",
          left: "10%",
          bottom: "14%",
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisLine: lineOption,
            axisTick: {
              show: false,
            },
            axisLabel: {
              margin: 10,
              color: fontColor,
              textStyle: {
                fontSize: 14,
              },
            },
          },
        ],
        yAxis: [
          {
            position: "left",
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 16,
              color: "#333",
            },
            name: "速度均方根值（nm/s）",
            axisLabel: {
              formatter: "{value}",
              color: fontColor,
            },
            axisTick: {
              show: false,
            },
            axisLine: lineOption,
            splitLine: lineOption,
          },
        ],
        series: [
          {
            type: "bar",
            data: sData,
            barWidth: "20px",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#00BD89", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#C9F9E1", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
          },
        ],
      };

      let chart1 = echarts.init(this.$refs.barEchart2);
      chart1.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.EchartBarComponents {
  height: calc(100% - 74px);
  padding: 20px;
  .title {
    width: 100%;
    line-height: 60px;
    height: 60px;
  }
  .main {
    height: calc(100% - 60px);
    display: flex;
    justify-content: space-around;
    .echart {
      width: 40%;
      min-width: 600px;
      height: 430px;
    }
  }
}
</style>
