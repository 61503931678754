/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-19 15:34:59
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-19 18:34:14
 */
import cropper from "@/components/public/CropperComponents"; //裁剪图片
import {
    detail
} from "@/api/equipment.js";
import {
    uploadPic,
    update
} from "@/api/device.js";
export default {
    components: {
        cropper
    },
    data() {
        return {
            isCropper: false,
            fileName: "",
            cropperImgUrl: "",
            uploadS: false,
            blobData: null,
            deviceOverview: {},
            deviceInfo: {}
        }
    },

    computed: {},
    //监听执行
    watch: {},
    mounted() {
        this.getInfo()
    },
    methods: {

        getInfo() {
            detail(this.$route.query.deviceId).then((res) => {
                if (this.apiReply(res)) {
                    this.deviceInfo = {
                        ...res.data.data.deviceInfo,
                    };
                }
            });
        },

        imgUrlF(data) {
            this.blobData = data;
            this.cropperImgUrl = URL.createObjectURL(data);
            let files = new window.File([this.blobData], this.fileName);
            const formData = new FormData();
            formData.append("file", files);
            formData.append("name", this.fileName);
            formData.append("index", 1);
            let deviceInfo = {
                ...this.deviceInfo
            }
            uploadPic(formData).then((res) => {
                if (this.apiReply(res)) {
                    // 更新图片接口
                    delete deviceInfo.picList[0].picToken;
                    deviceInfo.picList[0].operationId = 2;
                    deviceInfo.picList.push({
                        id: res.data.data.picId,
                        index: 1,
                        type: "pic",
                        operationId: 1,
                    });
                    this.updateDataF(deviceInfo); //调用更新接口
                }
            });
        },
        updateDataF(deviceInfo) {
            update(deviceInfo).then((res) => {
                if (this.apiReply(res)) {
                    this.$message({
                        message: "已更换测点图片",
                        type: "success",
                    });
                    this.$emit("updateImg", this.cropperImgUrl)
                }
            });
        },
        cropperF() {
            this.isCropper = false;
        },
        handleChange(file, fileList) {

            this.cropperImgUrl = URL.createObjectURL(file.raw);

            this.fileName = file.name;
            this.isCropper = true;
        },
        upload(fileObj) {
            //上传
            if (this.uploadS == false) {
                this.uploadS = true;
                const formData = new FormData();
                const _file = fileObj.file;

                if (!this.beforeAvatarUpload(_file)) {
                    //验证是否符合标准
                    this.uploadS = false;
                    return;
                }
                console.log(fileObj.file)
            }
        },
        addImgF() {
            // 上传图片
            this.$refs.uploadBtn.$el.click();
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg";
            const isPng = file.type === "image/png";
            const isLt2M = file.size / 1024 / 1024 < 50;
            let type = true;

            if (!isJPG && !isPng) {
                type = false;
                this.$message.error("上传图片必须是 JPG 或 PNG格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 2MB!");
            }
            return type && isLt2M;
        },
    }
}