<!--
 * @Description: 瀑布图
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-18 19:56:18
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-24 10:43:17
-->
<template>
  <div class="EchartSpectrumComponents">
    <div class="Chart">
      <div class="title">速度均方根值趋势图</div>
      <div class="chart" ref="EchartSpectrum1"></div>
    </div>
    <div class="Chart">
      <div class="chart chartGl" ref="EchartSpectrum2"></div>
    </div>
  </div>
</template>

<script>
import { spectrum } from "@/api/figure.js";
const echarts = require("echarts");
require("echarts-gl");

export default {
  name: "EchartSpectrumComponents",
  components: {},
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      timeData: null,
      chart2: null,
      chart1: null,
      rawData: {},
      spectrumData: [],
    };
  },

  computed: {},
  //监听执行
  watch: {
    chartData: {
      deep: true,
      handler: function (newV, oldV) {
        this.init();
      },
    },
  },
  mounted() {},
  methods: {
    init() {
      // 频谱图
      this.timeData = [];
      for (let i = 0; i < this.chartData.length; i++) {
        this.timeData.push(
          this.$formatDateTime(this.chartData[i].uploadTime, "MM-dd HH:mm")
        );
        if (this.chartData[i].rawDataFlag) {
          this.rawData = this.chartData[i];
        }
      }

      this.chart1 ? this.chart1.dispose() : "";
      this.getSpectrumDataF();
      this.canvasF1();
    },
    getSpectrumDataF() {
      // 频谱数据
      if (this.rawData.id == undefined) {
        return;
      }
      spectrum({ id: this.rawData.id }).then((res) => {
        if (this.apiReply(res)) {
          this.spectrumData = { ...res.data.data };
          this.chart2 ? this.chart2.dispose() : "";
          this.canvasF2();
        }
      });
    },
    canvasF1() {
      let XArr = this.chartData.map(function (str) {
        return str.xeigenvalue.rms;
      });
      let YArr = this.chartData.map(function (str) {
        return str.yeigenvalue.rms;
      });
      let ZArr = this.chartData.map(function (str) {
        return str.zeigenvalue.rms;
      });
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          selectedMode: "single",
          show: true,
          icon: "circle",
          left: "2%",
          data: ["X", "Y", "Z"],
        },
        grid: {
          left: "2%",
          right: "4%",
          top: "20%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          right: "4%",
          showTitle: false,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: {},
          },
          tooltip: {
            // 和 option.tooltip 的配置项相同
            show: true,
            formatter: function (param) {
              return "<div>" + param.title + "</div>"; // 自定义的 DOM 结构
            },
            backgroundColor: "#efefef",
            textStyle: {
              fontSize: 12,
            },
            extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);padding:6px", // 自定义的 CSS 样式
          },
        },
        xAxis: {
          data: this.timeData,
        },
        yAxis: [
          {
            name: "速度（nm/s²）",
          },
        ],
        series: [
          {
            name: "X",
            type: "line",
            color: "#80a188",
            areaStyle: {
              opacity: 0.1,
              color: "#80a188",
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: XArr,
          },
          {
            name: "Y",
            type: "line",
            color: "#1e87a9",
            areaStyle: { opacity: 0.1, color: "#1e87a9" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: YArr,
          },
          {
            name: "Z",
            type: "line",
            color: "#7c63ff",
            areaStyle: { opacity: 0.1, color: "#7c63ff" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: ZArr,
          },
        ],
      };
      this.chart1 = echarts.init(this.$refs.EchartSpectrum1);
      this.chart1.setOption(option);
    },
    canvasF2() {
      let time = this.$formatDateTime(
        this.rawData.uploadTime,
        "yyyy-MM-dd HH:mm"
      );
      let xData = this.delwidthF(
        this.spectrumData.xfreqDomain.x,
        this.rawData.uploadTime,
        this.spectrumData.xfreqDomain.y
      );
      let yData = this.delwidthF(
        this.spectrumData.yfreqDomain.x,
        this.rawData.uploadTime,
        this.spectrumData.yfreqDomain.y
      );
      let zData = this.delwidthF(
        this.spectrumData.zfreqDomain.x,
        this.rawData.uploadTime,
        this.spectrumData.zfreqDomain.y
      );

      let option = {
        tooltip: {},
        grid3D: {},
        // 默认情况下, x, y, z 分别是从 0 到 1 的数值轴
        legend: {
          selectedMode: "single",
          show: false,
          data: ["X", "Y", "Z"],
        },
        xAxis3D: {
          name: "频率",
        },
        yAxis3D: {
          name: "时间",
          type: "time",
          splitNumber: 5,
          min: this.rawData.uploadTime - 0 - 24 * 60 * 60 * 1000,
          max: this.rawData.uploadTime - 0 + 24 * 60 * 60 * 1000,
          splitLine: {
            show: false,
          },
        },
        zAxis3D: { name: "幅度" },

        series: [
          {
            name: "X",
            type: "line3D",
            data: xData,
          },
          {
            name: "Y",
            type: "line3D",
            data: yData,
          },
          {
            name: "Z",
            type: "line3D",
            data: zData,
          },
        ],
      };
      this.chart2 = echarts.init(this.$refs.EchartSpectrum2);
      this.chart2.setOption(option);
    },
    delwidthF(xdata, y, zdata) {
      let Arr = [];
      for (let i = 0; i < zdata.length; i++) {
        let arr = [xdata[i], y, zdata[i]];
        Arr.push(arr);
      }
      return Arr;
    },
  },
};
</script>
<style lang="scss" scoped>
.EchartSpectrumComponents {
  width: 100%;
  .Chart {
    width: 100%;
    .title {
      padding: 20px 0;
      text-align: left;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #313131;
    }
    .chart {
      width: 100%;
      height: 352px;
      > div {
        width: 100% !important;
        canvas {
          width: 100% !important;
        }
      }
    }
    .chartGl {
      height: 430px;
    }
  }
}
</style>
