<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-14 09:31:08
 * @LastEditors: 支磊
 * @LastEditTime: 2021-09-26 18:09:26
-->
<template>
  <div class="devienceAtlas">
    <div class="Title">图谱</div>
    <div class="content">
      <div class="selectHead">
        <div>
          <div class="lable">切换测点：</div>
          <el-select v-model="point" placeholder="请选择" size="small">
            <el-option
              v-for="item in pointArr"
              :key="item.instId"
              :label="item.pointName"
              :value="item.instId"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <div class="lable">图谱类型：</div>
          <el-select v-model="value" placeholder="请选择" size="small">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="value != 'barC'">
          <div class="lable">开始时间：</div>
          <el-date-picker
            v-model="startTime"
            align="right"
            :clearable="false"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            size="small"
            value-format="timestamp"
          >
          </el-date-picker>
        </div>
        <div v-if="value != 'barC'">
          <div class="lable">结束时间：</div>
          <el-date-picker
            v-model="endTime"
            align="right"
            type="date"
            :clearable="false"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            size="small"
            value-format="timestamp"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <!-- 无数据展示 -->
    <div v-if="chartData.length == 0" class="notData" v-loading="loading">
      <div>
        <img style="width: 96px" src="/img/notData.png" alt="" />
        <p style="color: #909399; font-size: 14px; text-align: center">
          暂无数据
        </p>
      </div>
    </div>
    <!-- 无数据展示 end -->
    <!-- 趋势图 -->
    <trend-c
      :chartData="chartData"
      v-if="value == 'trendC'"
      :title="echartTitle"
    />
    <!-- 趋势图 end-->
    <!-- 棒值图 -->
    <bar-c
      :title="echartTitle"
      :chartData="barData"
      v-if="value == 'barC' && chartData.length != 0"
    />
    <!-- 棒值图 end-->
    <!-- 频谱图 -->
    <waveform-c
      :title="echartTitle"
      :chartData="chartData"
      v-if="value == 'waveformC' && chartData.length != 0"
    />
    <!-- 频谱图 end-->
    <!-- 瀑布图 -->
    <spectrum-c
      :chartData="chartData"
      v-if="value == 'spectrumC' && chartData.length != 0"
    />
    <!-- 瀑布图 end-->
  </div>
</template>

<script>
import { list } from "@/api/point.js";
import { trend, bar } from "@/api/figure.js";
import trendC from "./private/EchartTrendComponents"; //趋势图
import barC from "./private/EchartBarComponents"; //棒值图
import waveformC from "./private/EchartWaveformComponents"; //频谱图
import spectrumC from "./private/EchartSpectrumComponents"; //瀑布图

export default {
  name: "devienceAtlas",
  components: { trendC, barC, waveformC, spectrumC },
  data() {
    return {
      loading: false,
      echartTitle: "",
      value: "trendC",
      options: [
        { label: "趋势图", value: "trendC" },
        { label: "频谱图", value: "waveformC" },
        { label: "棒值图", value: "barC" },
        // { label: "瀑布图", value: "spectrumC" },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      startTime: "",
      endTime: "",
      chartData: [],
      pointArr: [],
      point: "", //测点
      barData: {},
      timeFSpace: null, //存储settimeout方法
    };
  },

  computed: {
    timeAll() {
      let str = "";
      str = this.startTime + this.endTime;
      return str;
    },
  },
  //监听执行
  watch: {
    timeAll() {},
    point() {
      this.getChartDataF();
    },
    value() {
      this.getChartDataF();
    },
    startTime() {
      console.log("startTime", this.startTime);
      clearTimeout(this.timeFSpace);
      if (this.startTime > this.endTime) {
        this.endTime = this.startTime + 7 * 24 * 60 * 60 * 1000;
      }
      this.timeFSpace = setTimeout(() => {
        this.getChartDataF();
      }, 1200);
    },
    endTime() {
      clearTimeout(this.timeFSpace);
      if (this.startTime > this.endTime) {
        this.startTime = this.endTime - 7 * 24 * 60 * 60 * 1000;
      }
      console.log("endTime", this.endTime);
      this.timeFSpace = setTimeout(() => {
        this.getChartDataF();
      }, 1200);
    },
  },
  mounted() {
    this.init();
    this.getPoint();
  },
  methods: {
    getChartDataF() {
      let name = null;
      for (let i = 0; i < this.pointArr.length; i++) {
        if (this.pointArr[i].instId == this.point) {
          name = this.pointArr[i].pointName;
        }
      }
      let time =
        this.$formatDateTime(this.startTime, "yyyyMMdd") +
        "-" +
        this.$formatDateTime(this.endTime, "yyyyMMdd");
      switch (this.value) {
        case "trendC":
          // 趋势图
          this.echartTitle = {
            title1: name + "加速度峰值趋势图" + time,
            title2: name + "速度均方根值趋势图" + time,
          };
          this.getData();
          break;
        case "barC":
          // 棒值图
          this.getBarData();
          break;
        case "waveformC":
          // 频谱图
          this.echartTitle = {
            title1: name + "速度均方根值趋势图" + time,
            title2: name + "波形图" + time,
            title3: name + "频谱图" + time,
          };
          this.getData();
          break;
        case "spectrumC":
          // 瀑布图
          this.getData();
          break;
        default:
          break;
      }
    },
    init() {
      let date = new Date();
      let today = date.getTime();
      this.endTime = today;
      this.startTime = today - 7 * 24 * 60 * 60 * 1000;
    },
    getPoint() {
      let data = {
        deviceId: this.$route.query.deviceId,
        pageNo: 1,
        pageSize: 500,
        searchString: "",
      };
      list(data).then((res) => {
        if (this.apiReply(res)) {
          this.pointArr = [...res.data.data.dataList];
          if (this.pointArr.length != 0) {
            this.point = this.pointArr[0].instId;
          }
        }
      });
    },
    getData() {
      this.loading = true;
      let data = {
        id: this.point,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      trend(data).then((res) => {
        if (this.apiReply(res)) {
          this.chartData = [...res.data.data];
        }
        this.loading = false;
      });
    },

    getBarData() {
      bar({ id: this.point }).then((res) => {
        if (this.apiReply(res)) {
          this.barData = {
            ...res.data.data,
          };
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.notData {
  height: calc(100% - 74px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.devienceAtlas {
  padding: 20px;
  height: 100%;
  .Title {
    @extend .title_green;
  }
  .content {
    padding-top: 24px;
    .selectHead {
      display: flex;
      > div {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .lable {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #8691a3;
        }
      }
    }
  }
}
</style>
