/*
 * @Description: 选择场景时用到的接口
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2020-10-19 11:48:35
 * @LastEditors: 支磊
 * @LastEditTime: 2021-10-22 11:33:18
 */
import base from '@/config'; // 导入接口域名列表
import axios from '@/utils/cmshttps'; // 导入http中创建的axios实例
import qs from 'qs'

function headerF() {
  let header = {}
  if (localStorage.getItem("SiteId") && localStorage.getItem("SiteId") != -1) {
    header = {
      'SiteId': localStorage.getItem("SiteId")
    }
  }

  return header
}

export function trend(data) {
  let header = headerF()
  return axios.get(`${base.cmsBaseApi}/figure/v2/trend?id=${data.id}&startTime=${data.startTime}&endTime=${data.endTime}`, {
    headers: {
      ...header
    }
  });
}
export function bar(data) {
  let header = headerF()
  return axios.get(`${base.cmsBaseApi}/figure/v2/bar?id=${data.id}`, {
    headers: {
      ...header
    }
  });
}
export function waveform(data) { //频谱图
  let header = headerF()
  return axios.get(`${base.cmsBaseApi}/figure/v2/waveform?id=${data.id}`, {
    headers: {
      ...header
    }
  });
}
export function spectrum(data) { //瀑布图
  let header = headerF()
  return axios.get(`${base.cmsBaseApi}/figure/v2/spectrum?id=${data.id}`, {
    headers: {
      ...header
    }
  });
}