<!--
 * @Description: 趋势图
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-17 14:45:51
 * @LastEditors: 支磊
 * @LastEditTime: 2021-07-06 09:57:49
-->
<template>
  <div class="EchartTrendComponents">
    <div class="Chart">
      <div class="title">加速度峰值趋势图</div>
      <div class="chart" id="echart1" ref="EchartTrend1"></div>
    </div>
    <div class="Chart">
      <div class="title">速度均方根值趋势图</div>
      <div class="chart" ref="EchartTrend2"></div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "EchartTrendComponents",
  components: {},
  props: {
    title: {},
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      timeData: null,
      temperatureArr: null,
      chart2: null,
      chart1: null,
    };
  },

  computed: {},
  //监听执行
  watch: {
    chartData: {
      deep: true,
      handler: function (newV, oldV) {
        console.log("chartData", this.chartData);
        this.timeData = this.chartData.map((str) => {
          return this.$formatDateTime(str.uploadTime, "MM-dd HH:mm");
        });
        this.temperatureArr = this.chartData.map(function (str) {
          return str.temperature;
        });
        this.chart2 ? this.chart2.dispose() : "";
        this.chart1 ? this.chart1.dispose() : "";
        this.canvasF1();
        this.canvasF2();
      },
    },
  },
  mounted() {},
  methods: {
    canvasF1() {
      let XArr = this.chartData.map(function (str) {
        return str.xeigenvalue.pk;
      });
      let YArr = this.chartData.map(function (str) {
        return str.yeigenvalue.pk;
      });
      let ZArr = this.chartData.map(function (str) {
        return str.zeigenvalue.pk;
      });

      this.chart1 = echarts.init(this.$refs.EchartTrend1);
      let option = {
        title: {
          text: this.title.title1,
          show: false,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "circle",
          left: "2%",
          data: ["X", "Y", "Z", "温度"],
        },
        grid: {
          left: "2%",
          right: "4%",
          top: "20%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          right: "4%",
          showTitle: false,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: {},
          },
          tooltip: {
            // 和 option.tooltip 的配置项相同
            show: true,
            formatter: function (param) {
              return "<div>" + param.title + "</div>"; // 自定义的 DOM 结构
            },
            backgroundColor: "#efefef",
            textStyle: {
              fontSize: 12,
            },
            extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);padding:6px", // 自定义的 CSS 样式
          },
        },
        xAxis: {
          data: this.timeData,
        },
        yAxis: [
          {
            name: "加速度（m/s²）",
          },
          {
            type: "value",
            name: "温度（℃）",
            position: "right",
            axisLine: {},
          },
        ],

        series: [
          {
            name: "X",
            type: "line",
            color: "#80a188",
            areaStyle: {
              opacity: 0.1,
              color: "#80a188",
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: XArr,
          },
          {
            name: "Y",
            type: "line",
            color: "#1e87a9",
            areaStyle: { opacity: 0.1, color: "#1e87a9" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: YArr,
          },
          {
            name: "Z",
            type: "line",
            color: "#7c63ff",
            areaStyle: { opacity: 0.1, color: "#7c63ff" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: ZArr,
          },
          {
            name: "温度",
            type: "line",
            color: "#da55ea",
            areaStyle: { opacity: 0.1, color: "#da55ea" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            yAxisIndex: 1,
            data: this.temperatureArr,
          },
        ],
      };
      this.chart1.setOption(option);
      this.chart1.on("legendselectchanged", (obj) => {
        this.chart2.dispatchAction({
          type: "legendToggleSelect",
          name: obj.name,
        });
      });
    },
    canvasF2() {
      let XArr = this.chartData.map(function (str) {
        return str.xeigenvalue.rms;
      });
      let YArr = this.chartData.map(function (str) {
        return str.yeigenvalue.rms;
      });
      let ZArr = this.chartData.map(function (str) {
        return str.zeigenvalue.rms;
      });
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          show: false,
          icon: "circle",
          left: "2%",
          data: ["X", "Y", "Z", "温度"],
        },
        grid: {
          left: "2%",
          right: "4%",
          top: "20%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          right: "4%",
          showTitle: false,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: {},
          },
          tooltip: {
            // 和 option.tooltip 的配置项相同
            show: true,
            formatter: function (param) {
              return "<div>" + param.title + "</div>"; // 自定义的 DOM 结构
            },
            backgroundColor: "#efefef",
            textStyle: {
              fontSize: 12,
            },
            extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);padding:6px", // 自定义的 CSS 样式
          },
        },
        xAxis: {
          data: this.timeData,
        },
        yAxis: [
          {
            name: "速度（nm/s²）",
          },
          {
            type: "value",
            name: "温度（℃）",
            position: "right",
            axisLine: {},
          },
        ],
        series: [
          {
            name: "X",
            type: "line",
            color: "#80a188",
            areaStyle: {
              opacity: 0.1,
              color: "#80a188",
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: XArr,
          },
          {
            name: "Y",
            type: "line",
            color: "#1e87a9",
            areaStyle: { opacity: 0.1, color: "#1e87a9" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: YArr,
          },
          {
            name: "Z",
            type: "line",
            color: "#7c63ff",
            areaStyle: { opacity: 0.1, color: "#7c63ff" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: ZArr,
          },
          {
            name: "温度",
            type: "line",
            color: "#da55ea",
            areaStyle: { opacity: 0.1, color: "#da55ea" },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                },
              },
            },
            yAxisIndex: 1,
            data: this.temperatureArr,
          },
        ],
      };
      this.chart2 = echarts.init(this.$refs.EchartTrend2);
      this.chart2.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.EchartTrendComponents {
  .Chart {
    .title {
      padding: 20px 0;
      text-align: left;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #313131;
    }
    .chart {
      width: 100%;
      height: 352px;
    }
  }
}
</style>
