<!--
 * @Description: 添加测点选择报警规则
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-15 10:26:08
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-15 16:38:16
-->
<template>
  <div class="SelectAlertRulesDialogComponents">
    <div class="model"></div>
    <div class="main">
      <i class="close el-icon el-icon-close" @click="closeF()"></i>
      <div class="head">选择报警规则</div>
      <div class="table">
        <el-table
          ref="Table"
          :data="tableData"
          :height="400"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            align="center"
            label="规则名称"
            width="220"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="报警类型"
            width="220"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="categoryName"
            label="传感器类型"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            label="报警规则"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.rule == 1 ? "超限" : "低限" }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="特征值" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.eigenvalue | eigen }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="centerFrequency"
            label="中心频率"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="bandwidth"
            label="带宽"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column align="center" label="轴向" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ axial[scope.row.axial] }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="benchmark"
            label="基准"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="lowQuote"
            label="低报"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="highQuote"
            label="高报"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="highHighQuote"
            label="高高报"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
      </div>
      <div class="foot">
        <el-button @click="closeF()" size="small" class="cancelBtn" plain
          >取消</el-button
        >
        <el-button @click="submintF()" type="primary" size="small"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { pointRuleList } from "@/api/rule.js";
export default {
  name: "SelectAlertRulesDialogComponents",
  components: {},
  props: {
    rejectIds: {},
  },
  data() {
    return {
      axial: [0, "x(A)", "y(B)", "z(C)"],
      tableData: [],
      selectData: [],
    };
  },
  filters: {
    eigen(data) {
      let text = "加速度峰值";
      switch (data) {
        case 1:
          text = "加速度峰值";
          break;
        case 2:
          text = "速度均方根";
          break;
        case 3:
          text = "电压";
          break;
        case 4:
          text = "温度";
          break;
        default:
          break;
      }
      return text;
    },
  },
  computed: {},
  //监听执行
  watch: {},
  mounted() {
    this.getDataF();
  },
  methods: {
    handleSelectionChange(val) {
      console.log("val");
      this.selectData = val;
    },
    getDataF() {
      console.log(this.rejectIds);
      let data = {
        searchString: "",
        pageNo: "",
        pageSize: 500,
        rejectIds: this.rejectIds,
      };
      pointRuleList(data).then((res) => {
        if (this.apiReply(res)) {
          this.tableData = [...res.data.data.dataList];
          this.$nextTick(function () {
            this.toggleSelection(this.selectRule);
          });
        }
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          console.log(row);
          this.$refs.Table.toggleRowSelection(row, true);
        });
      }
    },
    submintF() {
      // 保存
      this.$emit("close");
      this.$emit("pushData", this.selectData);
    },
    closeF() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.SelectAlertRulesDialogComponents {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  .model {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000;
  }
  .main {
    width: 75%;
    min-width: 980px;
    background: #fff;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    padding: 28px;
    .close {
      color: #909399;
      font-size: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 9;
      &:hover {
        color: $main_color;
      }
    }
    .head {
      @extend .title_green;
      margin-bottom: 6px;
    }
    .foot {
      padding-top: 20px;
      text-align: right;
    }
  }
}
</style>
